html {
  font-size: $font-size--base;

  // The following lines are used to determine via JavaScript the current
  // viewport width
  font-family: 'phone';

  @include mediaQueries('ML', 'min') {
    font-family: 'desktop';
  }

  /**
    * Smooth scrolling on the whole document
    */
  scroll-behavior: smooth;

  // global scale

  @media screen and (max-width: 359px) {
    font-size: $font-size--base * 0.75;
  }
  @media screen and (min-width: 360px) and (max-width: 399px) {
    font-size: $font-size--base * 0.85;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    font-size: $font-size--base * 0.75;
  }
  @media screen and (min-width: 1921px) {
    font-size: $font-size--base * 1.25;
  }
}

body {
  position: relative;
  background-color: $color--white;
  color: $color--black;
  font-family: $font-family--primary;
  font-weight: $font-weight--regular;
  margin: 0;

  overflow-x: hidden;
  //   overflow: hidden;
  // background-image: url('/src/img/space1.webp');
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-position: center center;
  // background-size: cover;

  scroll-behavior: smooth;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1.6rem;
  line-height: 2.4rem;

  // @include mediaQueries('L', 'max'){
  //   hyphens: auto;
  //   word-wrap: break-word;
  // }
}

* {
  box-sizing: border-box;
  &:empty {
    margin: 0;
    padding: 0;
  }
}
*:before,
*:after {
  box-sizing: inherit;
}

ul{
  list-style: none;
  margin: 0;
  padding-left: 0;
}

ol li{
  margin-left: 1em;
}

dl,
dt,
dd {
  margin: 0;
}

b, strong{
  font-weight: $font-weight--bold;
}

img {
  vertical-align: middle;
  width: 100%;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

.sr-only {
  @include sr-only;
}

.l-wrapper {
  @include wrapper;
}

.font-hand {
  font-family: $font-family--header;
  font-weight: $font-weight--regular;
  font-style: italic;
  line-height: 2;
}

.mobile-hidden {
  @include mediaQueries('ML', 'max') {
    display: none !important;
  }
}
.desktop-hidden {
  @include mediaQueries('ML', 'min') {
    display: none !important;
  }
}
