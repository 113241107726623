.c-moon {
  display: none;
  @include mediaQueries("ML", "min") {
    display: inline-block;
    svg {
      width: 20rem;
      stroke: $color--4;
      stroke-width: 0.3px;
      opacity: .7;
    }
  }
}