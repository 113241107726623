// ==========================================================================
// = Mixins
// ==========================================================================

@use "variables" as *;

// Media Queries
@mixin mediaQueries($breakpoint, $sizeType) {
  @if map-has-key($breakpoints, $breakpoint) {
    @if ($sizeType== 'min') {
      @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    } @else if ($sizeType== 'max') {
      @media screen and (max-width: map-get($breakpoints, $breakpoint) - 1px) {
        @content;
      }
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        +"Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin mediaQueriesRange($breakpointMin, $breakpointMax) {
  @if (map-has-key($breakpoints, $breakpointMin) and map-has-key($breakpoints, $breakpointMax)) {
      @media screen and (min-width: map-get($breakpoints, $breakpointMin)) and (max-width: map-get($breakpoints, $breakpointMax) - 1px) {
          @content;
      }
  } @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpointMin}` or `#{$breakpointMax}`. "
      +"Please make sure it is defined in `$breakpoints` map.";
  }
}

// Maps a scss map variable to three different breakpoints
@mixin fit-for-all-screens($property, $map) {
  // mobile first approach
  #{$property}: map-get($map, 'phone');

  @include mediaQueries('L', 'min'){
    #{$property}: map-get($map, 'desktop');
  }
}


// Only display content to screen readers
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  // /* for Internet Explorer */
  // clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  border: 0;
}

