
// plugins
@import 'swiper/swiper';
@import 'swiper/modules/pagination/pagination';

//utils
@import '../scss/utils/mixins' ;
@import '../scss/utils/variables';
@import '../scss/utils/reset';
@import '../scss/utils/layout';

//global
@import 'base';

//components
@import "../components/shapes/index";
@import "../components/moon/index";
@import "../components/background/index";
@import "../components/cta/index";
@import "../components/cursor/index";
@import "../components/scrollIcon/index";
@import "../components/pagination/index";


// modules
@import "../modules/header/index";
@import "../modules/hero/index";
@import "../modules/products/index";
@import "../modules/carousel/index";
@import "../modules/team/index";
@import "../modules/form/index";
@import "../modules/footer/index";
