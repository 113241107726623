.m-team {

  @include section($zIndex--team);

  background-color: $color--4;
  position: relative;
  padding: 5rem 0 0 0;
  @include mediaQueries('ML', 'min') {
    padding: 15rem 0 12rem;
    height: 5rem;
  }

  .l-wrapper {
    height: 100%;
  }

  &__bgImage {
    max-width: 30rem;
    position: absolute;
    top: 25%;
    right: 50%;
    transform: translate(50%, -60%);


    @include mediaQueries('M', 'min') {
      top: 25%;
      right: 3rem;
      transform: translateY(-70%);
    }
    @include mediaQueries('ML', 'min') {
      top: unset;
      overflow: hidden;
      bottom: 0;
      right: unset;
      left: 50%;
      transform: translateX(-45%);
      max-width: 42rem;
    }

    width: 100%;
    height: 100%;
    picture{
      position: absolute;
      width: 100%;
      bottom: 0;
      opacity: 0;
      // filter: blur(10px);
      transition: opacity .3s;

      &.is--visible {
        opacity: 1;
        filter: blur(0px);

      }
    }

  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;

    @include mediaQueries('ML', 'min') {
      height: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__header {
    margin-bottom: 2rem;
    font-size: 2.3rem;
    line-height: 1.2;
    padding-top: 1rem;

    @include mediaQueries('ML', 'min') {
      margin-bottom: 5rem;
      font-size: 5rem;
    }

    span:first-of-type {
      display: block;
      font-family: $font-family--header;
      font-weight: $font-weight--regular;
      font-style: italic;
      margin-bottom: 1rem;
    
      @include mediaQueries('ML', 'min') {
        font-size: 4rem;
      }
    }
  }

  &__left {
    // max-width: 60rem;
    @include mediaQueries('M', 'min') {
      max-width: 70%;
    }
    @include mediaQueries('ML', 'min') {
      max-width: 50%;
      margin-top: -5%;
    }
  }

  &__text {
    @include mediaQueries('ML', 'min') {
      max-width: 45rem;
    }
  }

  &__right {
    margin-top: auto;
    max-width: 32%;

    @include mediaQueries('ML', 'max') {
      background-color: $color--7;
      width: 100vw;
      max-width: unset;
      position: relative;
      left: calc(-50vw + 50%);
      padding: 5rem 3.6rem;
      margin-top: 11rem;
    }

    @include mediaQueries('M', 'max') {
      margin-top: 30rem;
    }
  }
  &__right-bold {
    font-weight: $font-weight--bold;
    font-size: 1.8rem;
    margin-bottom: 3rem;
    max-width: 80%;;

    @include mediaQueries('ML', 'min') {
      font-size: 2.4rem;
      margin-bottom: 4rem;
    }
  }
  &__right-list {

    li {
      font-size: 1.2rem;
      line-height: 1.4;
      max-width: 34rem;

      span:first-of-type {
        display: block;
        font-weight: $font-weight--bold;
        font-size: 1.8rem;
        margin-bottom: 1rem;
  
        @include mediaQueries('ML', 'min') {
          font-size: 2.4rem;
          margin-bottom: 1rem;
        }
      }

      &:not(:last-child) {
        margin-bottom: 2rem;

        @include mediaQueries('ML', 'min') {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
