.m-products {
  @include section($zIndex--products);
  @include mediaQueries('ML', 'max') {
    height: auto;
  }
  color: $color--white;
  position: relative;
  z-index: 2;
  overflow: hidden;

  &__content {
    position: relative;
    @include wrapper;
    @include mediaQueries('ML', 'max') {
      padding-top: 8rem;
      padding-bottom: 8rem;

      &.m-products__analyze {
        background-image: linear-gradient(
          to top,
          $color--1 55%,
          transparent 100%
        );
      }
      &.m-products__concept {
        background-image: linear-gradient(
          to top,
          $color--2 55%,
          transparent 100%
        );
      }
      &.m-products__design {
        background-image: linear-gradient(
          to top,
          $color--3 55%,
          transparent 100%
        );
      }
      &.m-products__validate {
        background-image: linear-gradient(
          to top,
          $color--5 55%,
          transparent 100%
        );
      }
    }
    @include mediaQueries('ML', 'min') {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;

      height: 100vh;
    }
  }

  // work in progress

  &__bg {
    @include mediaQueries('ML', 'max') {
      max-width: 40rem;
      margin: 0 auto;
    }

    @include mediaQueries('ML', 'min') {
      position: fixed;
      top: 0;
      height: 100vh;
      left: 50%;
      img {
        max-width: 100%;
        width: auto;
      }
    }
  }

  &__bg-color,
  &__bg-space,
  &__bg-circle,
  &__bg-image,
  &__bg-moons,
  &__bg-graph {
    position: relative;
    @include mediaQueries('ML', 'min') {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }

  &__bg-color {
    @include mediaQueries('ML', 'min') {
      width: 100vw;
      height: 100vh;
      background-color: $color--1;
    }
  }

  &__bg-space {
    display: none;
    @include mediaQueries('ML', 'min') {
      display: block;
      opacity: 0;
      aspect-ratio: 1/1;
      width: 120vw;
      height: auto;

      background-image: url('/src/img/space1.webp');
      background-repeat: no-repeat;
      // background-attachment: fixed;
      background-position: center center;
      background-size: 100vw 100vh;

      border-radius: 50%;
      overflow: hidden;

      @media screen and (orientation: portrait) {
        height: 120vh;
        width: auto;
      }
    }
  }

  &__bg-circle {
    display: inline-block;

    height: auto;
    aspect-ratio: 1/1;
    border-radius: 50%;

    width: 80vw;
    max-width: 30rem;
    top: 30%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(-45deg);
    position: absolute;
    overflow: hidden;

    span {
      position: absolute;
      width: 100%;
      aspect-ratio: 1/1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 100%);
      background-color: $color--5;
      transform-origin: center center;
    }

    @include mediaQueries('ML', 'min') {
      width: 45rem;
      max-width: 100vw;
      top: 50%;
      // z-index: 1;

      span {
        transform: translate(-50%, 50%);
      }
    }
  }

  &__bg-image {
    img {
      max-width: 40rem;
      margin: 0 auto;
      display: inherit;
    }

    @include mediaQueries('ML', 'min') {
      z-index: 4;
      transform-origin: center;
      transform: none;

      img {
        position: absolute;
        transform: translate(-50%, -50%);
      }

      .m-products__analyze &,
      .m-products__design &,
      .m-products__validate & {
        img {
          max-width: 64vw;
          width: auto;
        }
      }

      .m-products__analyze & {
        mix-blend-mode: multiply;
      }
      .m-products__concept & {
        left: -8vw;
        img {
          width: auto;
          height: 100vh;
          max-width: inherit;
        }
      }
      .m-products__validate & {
        img {
          max-width: 64vw;
          width: auto;
        }
      }
    }
  }

  &__bg-clouds {
    display: none;
    @include mediaQueries('ML', 'min') {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      transform: translate(-50%, -50%);

      clip-path: polygon(0 0, 50vw 0, 50vw 100vh, 0 100vh, 0 0);

      img {
        position: absolute;
        // top: 50%;
        left: 50%;
        transform-origin: center center;
        max-width: 100%;
        &:nth-of-type(1) {
          top: 30%;
          width: 7%;
        }
        &:nth-of-type(2) {
          top: 45%;
          width: 15%;
        }
        &:nth-of-type(3) {
          top: 50%;
          width: 12%;
        }
        &:nth-of-type(4) {
          top: 35%;
          width: 9%;
        }
        &:nth-of-type(5) {
          top: 40%;
          width: 6%;
        }
      }
    }
  }

  &__bg-moons {
    @include mediaQueries('ML', 'min') {
      z-index: 6;
      .c-moon {
        transform-origin: center center;
        position: absolute;

        &:nth-child(1) {
          transform: translate(-38%, -40%);
        }
        &:nth-child(2) {
          transform: translate(-38%, -40%) scaleX(-1);
        }
        &:nth-child(3) {
          transform: translate(-50%, 0%) scale(1.1);
        }
      }
      svg {
        width: 45rem;
        stroke: $color--4;
        stroke-width: 0.5px;
      }
    }
  }

  &__bg-graph {
    display: none;
    @include mediaQueries('ML', 'min') {
      z-index: 6;
      display: block;
      .m-products__concept & {
        left: -8vw;
        svg {
          height: 100vh;
          // width: 22vw;
          // transform: translate(10vw, -4vw);
        }
      }
      .m-products__design & {
        // left: -8vw;
        svg {
          width: 64rem;
          // transform: translate(, 0);
        }
      }
    }
  }

  // ----------

  &__block {
    @include mediaQueries('ML', 'max') {
      max-width: 40rem;
      margin: 0 auto;
    }
    @include mediaQueries('ML', 'min') {
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: map-get($container__gutter--horizontal, 'desktop');
      right: map-get($container__gutter--horizontal, 'desktop');
      display: flex;
      align-items: center;

      &--1 {
        justify-content: center;
      }

      &--2 {
        justify-content: space-between;
      }
    }
    &--2 {
      @include mediaQueries('ML', 'max') {
        position: relative;
        padding-top: 5rem;
        &::before,
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 100rem;
          top: 0;
          background-color: inherit;
          height: 100%;
        }
        &::before {
          right: 100%;
        }
        &::after {
          left: 100%;
        }

        .m-products__analyze & {
          background-color: $color--1;
        }
        .m-products__concept & {
          background-color: $color--2;
        }
        .m-products__design & {
          background-color: $color--3;
        }
        .m-products__validate & {
          background-color: $color--5;
        }
      }
    }
  }

  &__header {
    transform-origin: center center;
    font-size: 2.3rem;
    line-height: 2.8rem;
    text-align: center;
    margin-bottom: 0.75em;
    @include mediaQueries('ML', 'min') {
      font-size: 6rem;
      line-height: 7.2rem;
    }
  }

  &__subtitle {
    // font-family: $font-family--header;
    font-size: 2.1rem;
    line-height: 2.8rem;
    margin-bottom: 1em;

    @include mediaQueries('ML', 'max') {
      max-width: 80%;
    }
    @include mediaQueries('ML', 'min') {
      font-size: 3rem;
      line-height: 4rem;
    }

  }

  &__title {
    font-size: 2.3rem;
    line-height: 2.8rem;
    font-weight: $font-weight--bold;
    @include mediaQueries('ML', 'min') {
      font-size: 4.8rem;
      line-height: 5.5rem;
    }
  }

  &__text {
    font-size: 1.4rem;
    line-height: 2.4rem;
    &::first-line,
    div:first-of-type {
      font-size: 1.9rem;
      line-height: 2.4rem;
      font-weight: $font-weight--bold;
    }
  }

  &__list {
    font-size: 1.9rem;
    line-height: 2.4rem;
    font-weight: $font-weight--bold;

    margin: 1.5em 0;
    li {
      margin: 0.5em 0;
    }
  }

  &__leftSide {
    max-width: 50rem;
    @include mediaQueries('ML', 'max') {
      margin-bottom: 5rem;
    }
  }

  &__rightSide {
    max-width: 28rem;
  }
}
