.c-cta {

  display: inline-block;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 3rem;
  transform: translateZ(0);

  @media (hover: hover) {
    .c-cta__text{
      transition: color 0.3s;
    }
    &:hover .c-cta__text{
      color: $color--white;
    }
  }

  &__dot{
    display: block;
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 50rem;
    top: 0;
    left: 0;
    aspect-ratio: 1/1;
    background-color: $color--6;
    border-radius: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%);
  }

  &__text{
    position: relative;
    z-index: 3;
    display: inline-block;

    font-size: 1.6rem;
    font-weight: $font-weight--bold;
    text-transform: uppercase;
    color: inherit;
    padding: 1rem 3rem 0.9rem;
  
    .c-cta--dark &{
      color: $color--white;
    }
  }

  &__bg{
    position: absolute;
    z-index: 1;
    top: 1px; right: 1px; bottom: 1px; left: 1px;
    display: inline-block;
  
    border: 1px solid rgba($color--black, 0.3);
    border-radius: 3rem;

    .c-cta--light & {
      border-color: $color--white;
    }
  
    .c-cta--dark &{
      background-color: $color--black;
      color: $color--white;
    }
  }


}
