.c-shape {
  &__moon {
    display: inline-block;
    // width: 10rem;
    // height: 20rem;
    // border: 1px solid $color--black;
    // border-radius: 10rem 0 0 10rem;
    // opacity: .2;
  }
}
