.m-hero {

  @include section(100);
  min-height: 50rem;
  max-height: 70rem;
  height: 100vh;
  @include mediaQueries('ML', 'max') {
    max-height: initial;
    min-height: auto;
  }

  position: relative;
  z-index: 3;
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color--white;
  overflow: hidden;

  &__bg {
    position: absolute;
    opacity: 0.5;
    top: -15rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;

    .c-moon {
      transform-origin: 40% 40%;
      position: absolute;
      transform: translate(-55%, 45%) rotate(-280deg);
      &:nth-child(2) {
        transform: translate(-30%, 45%) rotate(-315deg);
      }
      svg {
        width: 65vw;
      }
    }
  }

  &__content {
    @include wrapper;
    @include mediaQueries('ML', 'max') {
      max-width: 60rem;
    }
  }

  &__header {
    margin: 0 auto 0.4em;

    text-align: center;
    font-size: 4.4rem;
    line-height: 1;
    span {
      display: block;
    }

    @include mediaQueries('ML', 'min') {
      font-size: 9rem;
    }
  }

  &__text {
    margin: 0 auto;
    max-width: 40rem;

    font-size: 1.4rem;
    text-align: center;
  }
}
