// ==================================================================
// = Layout
// ==================================================================

// Widths
$content__width: 128rem; //1280px - 2*gutter-desktop
$content__width__tablet: 880px; //1280px - 2*gutter-desktop

// Layout gutter
$layout__gutter: 8px;

// module main paddings
$content__gutter--1: (
  phone: 40px,
  desktop: 80px,
);

$container__gutter--horizontal: (
  phone: 20px,
  desktop: 40px,
);

$container__gutter--vertical: (
  phone: 20px,
  desktop: 40px,
);


@mixin container__horizontal-gutter() {
  @include fit-for-all-screens('padding-left', $container__gutter--horizontal);
  @include fit-for-all-screens('padding-right', $container__gutter--horizontal);
}

@mixin container__vertical-gutter() {
  @include fit-for-all-screens('padding-top', $container__gutter--vertical);
  @include fit-for-all-screens('padding-bottom', $container__gutter--vertical);
}


@mixin wrapper() {
  width: 100%;
  padding: 0 3.6rem;
  margin: 0 auto;
  max-width: $content__width;
  @include mediaQueries('ML', 'min'){

  }
  // @include mediaQueries('L', 'min'){
  //   padding: 0;
  //   max-width: $content__width;
  // }
}

@mixin section($zIndex: 1){

  @include mediaQueries('ML', 'min'){
    // position: absolute;
    // top: 0;
    // right: 0;
    // left: 0;
    // z-index: $zIndex;

    min-height: 80rem;
    height: 100vh;
  }
}
