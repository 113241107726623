.m-form {
  position: relative;
  background-color: $color--white;
  padding: 5rem 0;
  @include mediaQueries('ML', 'min') {
    padding: 15rem 0;
    overflow: hidden;
    min-height: 85rem;
  }

  &__bg {
    position: absolute;
    top: 10rem;
    left: 45%;

    .c-moon {
      transform-origin: 0% 0%;
      position: absolute;
      &:first-of-type {
        transform: rotate(90deg) translate(-1px, -1px);
        svg {
          width: 70rem;
        }
      }
      &:nth-child(2) {
        transform: rotate(180deg) scale(1, -1);
        svg {
          width: 35rem;
          stroke-width: 0.5px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    @include mediaQueries('ML', 'min') {
      flex-direction: row;
      // flex-wrap: wrap;
    }
  }

  &__header,
  &__container {
    @include mediaQueries('ML', 'min') {
      flex-basis: 50%;
    }
  }

  &__privacy{
    margin: 3em 0;
    font-size: 65%;
    li{
      line-height: 1.3;
    }
  }

  &__header {
    font-size: 2.3rem;
    font-weight: $font-weight--bold;
    max-width: 20rem;
    margin-bottom: 4rem;
    line-height: 1;
    position: relative;
    z-index: 1;
    @include mediaQueries('ML', 'min') {
      font-size: 4.6rem;
      max-width: 40rem;
      margin-right: 25rem;
    }
  }

  &__submit {
    margin-top: 3rem;
  }

  &__input {
    $self: &;

    border: 1px solid $color--8;
    box-sizing: border-box;
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
    @include mediaQueries('ML', 'min') {
      width: 48rem;
    }

    &.is--error {
      margin-bottom: 3rem;
      border-color: red;

      .error {
        opacity: 1;
      }
    }

    .error {
      opacity: 0;
      position: absolute;
      font-size: 0.8em;
      color: red;
    }

    svg {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      stroke: $color--6;
      fill: transparent;
      stroke-width: 2px;
      pointer-events: none;
      z-index: 2;

      // opacity: 0;
    }

    input,
    select,
    textarea {
      border: none;
      outline: 0;
      font-size: 1.6rem;
      padding: 2rem 2rem 1rem;
      font-size: 1.6rem;
      width: 100%;
      resize: none;
      font-family: $font-family--primary;
      background-color: $color--white;
      position: relative;
    }

    select {
      appearance: none;
      -webkit-appearance: none;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi43MTIiIGhlaWdodD0iNy4yNjgiIHZpZXdCb3g9IjAgMCAxMi43MTIgNy4yNjgiPg0KICA8ZyBpZD0iR3JvdXBfMTUzNSIgZGF0YS1uYW1lPSJHcm91cCAxNTM1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMi43MTIpIHJvdGF0ZSg5MCkiPg0KICAgIDxwYXRoIGlkPSJJY29uX2lvbmljLWlvcy1hcnJvdy1mb3J3YXJkIiBkYXRhLW5hbWU9Ikljb24gaW9uaWMtaW9zLWFycm93LWZvcndhcmQiIGQ9Ik0xNi4zMjQsMTIuNTVsLTQuODEtNC44MDdhLjkuOSwwLDAsMSwwLTEuMjgzLjkxNi45MTYsMCwwLDEsMS4yODcsMGw1LjQ1LDUuNDQ2YS45MDcuOTA3LDAsMCwxLC4wMjYsMS4yNTNMMTIuOCwxOC42NDNhLjkwOS45MDksMCwwLDEtMS4yODctMS4yODNaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEuMjQ2IC02LjE5NikiLz4NCiAgPC9nPg0KPC9zdmc+DQo=);
      background-repeat: no-repeat;
      background-position: calc(100% - 2.1rem) center;
      option:first-child {
        display: none;
      }
    }

    label {
      font-size: 1.4rem;
      position: absolute;
      transform-origin: top left;
      transform: translate(0, 1.4rem) scale(1);
      transition: all 0.1s ease-in-out;
      margin-left: 2rem;
      z-index: 2;
    }

    &.is--active {
      label {
        //move the x coordinate and reduce size
        transform: translate(0, 4px) scale(0.75);
      }
    }

    input {
      &[type='checkbox'] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;

        & + label {
          margin-bottom: 0.3rem;
          padding-left: 3rem;
          display: block;
          position: relative;
          margin-left: 0;
          &::before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            margin-right: 10px;
            display: block;
            width: 2.3rem;
            height: 2.3rem;
            border: 1px solid $color--8;
            line-height: 1.25em;
          }
        }
        &:checked {
          & + label {
            &:before {
              content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4zNjIiIGhlaWdodD0iOS4xNDkiIHZpZXdCb3g9IjAgMCAxMy4zNjIgOS4xNDkiPg0KICA8ZGVmcz4NCiAgICA8c3R5bGU+DQogICAgICAuY2xzLTEgew0KICAgICAgICBmaWxsOiBub25lOw0KICAgICAgICBzdHJva2U6ICMwMDA7DQogICAgICAgIHN0cm9rZS13aWR0aDogMnB4Ow0KICAgICAgfQ0KICAgIDwvc3R5bGU+DQogIDwvZGVmcz4NCiAgPHBhdGggaWQ9IlBhdGhfMTY4NyIgZGF0YS1uYW1lPSJQYXRoIDE2ODciIGNsYXNzPSJjbHMtMSIgZD0iTS0xNDYwLjIxLTkwODIuNzM4bDQuNzg4LDQuNyw3LjE3My03LjAzNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQ2MC45MSA5MDg1Ljc5MSkiLz4NCjwvc3ZnPg0K');
              text-indent: 0.3rem;
              line-height: 1.6;
            }
          }
        }
        &:focus {
          & + label {
            &::before {
              border-color: $color--6;
            }
          }
        }
      }
    }

    &--checkbox {
      border: none;
      padding: 0;
      input {
        width: unset;
      }

      label {
        transform: none;
        display: block;
      }

      &.is--error {
        border: none;

        input {
          &[type='checkbox'] {
            & + label {
              &::before {
                border-color: red;
              }
            }
          }
        }
      }
    }
  }

  &__typ {
    margin-top: 1.5rem;
    font-size: 85%;
    text-decoration: underline;
  }


}
