.c-scrollIcon {
  position: fixed;
  display: inline-block;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  mix-blend-mode: difference;
  opacity: .65;

  display: block;
  height: 3em;
  aspect-ratio: 7/10 ;
  border: 1px solid $color--white;
  border-radius: 1em;

  transition: opacity .3s;
  &.is--hidden{
    opacity: 0;
  }

  &__arrow{
    display: block;
    height: 1em;
    width: 1px;
    background-color: $color--white;
    position: absolute;
    left: 50%;
    top: .25em;
    &::before{
      content: '';
      display: block;
      width: .5em;
      height: .5em;
      position: absolute;
      top: 100%;
      border-left: 1px solid $color--white;
      border-bottom: 1px solid $color--white;
      transform-origin: center center;
      transform: translate(-48%, -100%) rotate(-45deg);
    }
  }

}
