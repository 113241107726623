.c-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color--black;

  video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include mediaQueries('ML', 'max') {
    background-image: url('/src/img/space1.webp');
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: 1024px 682px;

    video{
      display: none;
    }

  }
}
