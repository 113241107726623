@include mediaQueries('ML', 'min') {
  // body *{
  //   cursor: none !important;
  // }

  .c-cursor {
    pointer-events: none;
    display: inline-block;
    position: fixed;
    // top: 50%;
    // left: 50%;
    transform: translate(-50%, -50%);
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $color--6;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 1000;
    transition: top 0.05s linear, left 0.05s linear, width .3s, height .3s, opacity .3s;
    opacity: 0;

    .is--btn & {
      width: .1rem;
      height: .1rem;
    }


    &__arrow {
      transform-origin: center center;
      opacity: 0;
      transition: all 0.3s;
      .is--left &{
        transform: scaleX(-1);
      }
    }

    .is--carousel & {
      opacity: 1;
      width: 14rem;
      height: 14rem;

      .c-cursor__arrow {
        opacity: 1;
      }
      
      &.is--end:not(.is--left),
      &.is--left.is--begin{
        width: 10rem;
        height: 10rem;
        opacity: .5;
      }

    }
  }
}
