.c-pagination {
  position: fixed;
  display: inline-block;
  z-index: 50;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all .3s;
  transform: translateX(100%);
  .is--menu &{
    transform: translateX(0);
    @include mediaQueries('ML', 'max') {
      background-color: rgba($color--white, 0.7);
    }
  }

  @include mediaQueries('ML', 'min') {
    mix-blend-mode: difference;
    transform: translateX(0);
    left: auto;
  }

  &__list {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;

    padding-right: 2em;
    padding-left: 2em;
    @media screen and (max-height: 480px) {
      padding-right: 6em;
    }

    position: relative;
    z-index: 2;
    gap: 3vh;
    @include mediaQueries('ML', 'min') {
      gap: 0.25em;
    }
  }

  &__el {
    cursor: pointer;

    @include mediaQueries('ML', 'min') {
      margin: 0.25em 0;
      &::before {
        content: '';
        display: block;
        background-color: transparent;
        width: 1.2rem;
        height: 1.2rem;
        border: 1px solid rgba($color--white, 1);
        border-radius: 50%;
        transition: 0.3s all;
      }
    }

    &.is--active {
      &::before {
        background-color: $color--white;
        border-color: transparent;
      }
    }
  }

  &__el-text {
    color: $color--black;
    font-weight: $font-weight--bold;
    @include mediaQueries('ML', 'min') {
      @include sr-only;
    }
  }

  &__lang {
    color: $color--black;
    margin-top: 1.5rem;
    font-weight: $font-weight--bold;
    // border: 1px solid $color--8;
    // border-radius: 50%;
    // padding: 4px 6px;
    text-decoration: underline;

    @include mediaQueries('ML', 'min') {
      display: none;
    }
  }
}
