.m-carousel {
  @include section($zIndex--carousel);

  position: relative;
  background-color: $color--white;
  padding: 5rem 0 0;
  @include mediaQueries('ML', 'min') {
    padding: 0 0 10rem 0;
    height: auto;

    // for hiding empty space
    &::before {
      content: '';
      display: block;
      height: 5rem;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $color--5;  //products color
    }
  }

  &__head {
    padding-bottom: 2em;
    @include mediaQueries('ML', 'min') {
      transform: translateY(5rem);
      position: relative;
      z-index: 2;
      overflow: hidden;
      padding-bottom: 5rem;
      padding-top: 10rem;
    }
  }

  &__head-bg {
    display: none;
    @include mediaQueries('ML', 'min') {
      display: block;
      position: absolute;
      top: 5rem;
      left: 45%;
      z-index: 1;
  
      .c-moon {
        transform-origin: 40% 40%;
        position: absolute;
        transform: translate(-55%, 45%) rotate(-280deg);
        &:nth-child(2) {
          transform: translate(-28%, 45%) rotate(-315deg);
        }
        svg {
          width: 80rem;
        }
      }
    }
  }

  &__header {
    margin: 0 auto 0.6em;
    max-width: 80rem;

    text-align: center;
    font-size: 3rem;
    line-height: 1.2;
    z-index: 2;
    position: relative;
    span {
      display: block;
      &.font-hand{
        font-size: 130%;
        line-height: 1.8;
      }
    }
    @include mediaQueries('ML', 'min') {
      font-size: 7rem;
    }
  }

  &__body {
    @include mediaQueries('ML', 'max') {
      background-color: $color--9;
    }

    position: relative;
    overflow: hidden;

    @media (hover: hover) {
      &:hover {
        cursor: none;
      }
    }

  }

  &__body,
  &__el {
    @include mediaQueries('ML', 'min') {
      min-height: 70rem;
      height: 100vh;
      max-height: 80rem;
    }
  }

  &__mask {
    display: none;
    @include mediaQueries('ML', 'min') {
      display: block;
      pointer-events: none;
      position: absolute;
      z-index: 14;
      top: 5rem;
      bottom: 5rem;
      width: calc(100% - 7.2rem);
      max-width: $content__width;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 0 0 100rem $color--white;
    }
  }

  &__outside-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__outside-el {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &:not(:first-of-type) {
      .m-carousel__outside-bg,
      .m-carousel__outside-content > * {
        // gsap
        opacity: 0;
      }
    }
  }

  &__outside-bg {
    display: none;
    @include mediaQueries('ML', 'min') {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: -2px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__outside-content {
    margin: 0 auto;
    color: $color--white;

    @include mediaQueries('ML', 'max') {
      max-width: 32rem;
      padding-top: 5rem;
    }
    @include mediaQueries('ML', 'min') {
      height: 100%;
      display: flex;
      position: relative;
      z-index: 4;

      justify-content: space-between;
      align-items: center;
      max-width: 128rem;

      @include container__horizontal-gutter();

      & > * {
        width: 30%;
      }
    }
  }

  &__swiper {
    // @include mediaQueries("ML", "max") {
    //   height: 70rem;
    // }
    position: relative;
    z-index: 10;
  }

  &__el {
    display: flex;
    align-items: flex-end;
    text-align: center;

    transition: opacity 0.3s;
    opacity: 0;
    &.swiper-slide-active {
      opacity: 1;
    }
  }

  &__el-content {
    position: relative;
    color: $color--white;
    text-align: center;
    max-width: 36rem;
    margin: 0 auto;
    // padding-bottom: 8rem;
    padding-top: 36rem;
    @include mediaQueries('ML', 'min') {
      display: flex;
      justify-content: center;
      align-items: baseline;
      max-width: 100%;
      height: 100%;
    }
  }
  &__el-content {
    @include mediaQueries('ML', 'max') {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 100%;
    }
  }

  &__el-img {
    max-width: 32rem;
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @include mediaQueries('ML', 'min') {
      max-width: 38rem;
      transform: translateY(10rem);
    }

    position: relative;
    &::before {
      content: attr(data-time);
      color: $color--black;
      font-weight: bold;
      text-align: left;
      padding: 0.7rem 2rem;
      font-size: 1.4rem;
      @include mediaQueries('ML', 'min') {
        font-size: 1.6rem;
        padding: 1.2rem 2rem;
      }

      display: block;
      position: absolute;
      bottom: 100%;
      left: 0;
      background-color: $color--white;
      background-image: url('/src/img/belka_bez_godziny.webp');
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: bottom center;
      border-radius: 1.8rem 1.8rem 0 0;
      overflow: hidden;
      width: 100%;
      aspect-ratio: 17/2;
    }
  }

  &__outside-text {
    font-weight: $font-weight--bold;
    font-size: 1.7rem;
    line-height: 2.6rem;
    text-align: center;
    @include mediaQueries('ML', 'min') {
      text-align: right;
      width: calc(50% - 26rem);
      padding-left: 3rem;
    }
  }

  &__outside-logo {
    max-width: 100%;
    width: auto;
    margin-bottom: 1rem;
  }

  &__outside-list {
    @include mediaQueries('ML', 'max') {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      text-align: center;
    }
    margin-top: 2rem;
    span {
      display: block;
      &:first-of-type {
        font-size: 3rem;
        line-height: 4rem;
        font-weight: $font-weight--bold;
        margin-top: 1rem;
      }
      &:last-of-type {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
    @include mediaQueries('ML', 'min') {
      text-align: left;
      width: calc(50% - 26rem);
    }
  }

  .swiper-pagination {
    @include mediaQueries('ML', 'min') {
      display: none;
    }

    background-color: $color--black;
    padding: 4rem 0;
    bottom: 0;
    .swiper-pagination-bullet {
      background-color: transparent;
      width: 1.4rem;
      height: 1.4rem;
      border: 1px solid rgba($color--white, 1);
      cursor: pointer;
    }
    .swiper-pagination-bullet-active {
      background-color: $color--white;
      border-color: transparent;
    }
  }
}
