.m-footer {
  position: relative;
  background-color: $color--black;
  color: $color--white;
  padding: 2rem 0 5rem;
  @include mediaQueries('ML', 'min') {
    padding: 4rem 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $zIndex--footer;
  }

  // &::after{
  //   content: '';
  //   display: block;
  //   width: 100vw;
  //   height: 10vh;
  //   background-color: $color--black;
  //   position: absolute;
  //   top: 100%;
  // }

  .l-wrapper {
    @include mediaQueries('ML', 'min') {
      display: flex;
      justify-content: space-between;
    }
  }

  &__links {
    line-height: 2.2;

    li {
      margin: 1rem 0;
    }
    > *:first-child {
      font-weight: $font-weight--bold;
    }

    @include mediaQueries('ML', 'min') {
      display: flex;
      gap: 3rem;
    }
  }

  &__copy {
    margin: 1rem 0;
    line-height: 2.2;
    @include mediaQueries('ML', 'max') {
      font-size: 1.1rem;
      margin-top: 4rem;
    }
  }

  a {
    position: relative;
    span{
        position: relative;
        z-index: 1;
    }
    &::before {
      content: '';
      display: inline-block;
      background-color: $color--white;
      position: absolute;
      top: calc(50% - 5px);
      left: 1px;
      height: 10px;
      width: 1px;
      z-index: 0;
      // opacity: 0;
      transform-origin: left center;
      border-radius: 11px;
    }
    @media (hover: hover) {
        transition: color 0.3s;
        &::before {
          transition: all 0.3s;
        }
        &:hover {
            color: $color--black;
          &::before {
            // transform: scaleX(1);
            opacity: 1;
            top: -2px;
            left: -10px;
            width: calc(100% + 20px);
            height: calc(100% + 4px);
          }
        }
      }
  }
}
