.m-header {
  padding: 5rem 0 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  @include mediaQueries('ML', 'max') {
    mix-blend-mode: difference;
  }

  .l-wrapper {
    display: flex;
    align-items: center;
    gap: .5rem;
    position: relative;
    @media screen and (min-height: 480px) {
      justify-content: center;
    }
    @include mediaQueries('ML', 'min') {
      justify-content: flex-end;
    }
  }

  &__logo {
    max-width: 19rem;
    svg {
      width: 100%;
      @include mediaQueries('ML', 'max') {
        fill: $color--white;
      }
    }
    @include mediaQueries('ML', 'min') {
      margin-right: auto;
    }
  }

  &__cta {
    display: none;

    @include mediaQueries('ML', 'min') {
      display: inline-block;
    }
  }

  &__lang {
    display: none;

    @include mediaQueries('ML', 'min') {
      position: relative;
      z-index: 3;
      display: inline-block;

      font-size: 1.6rem;
      font-weight: $font-weight--bold;
      text-transform: uppercase;
      color: inherit;
      padding: .9rem 0 0.8rem;
      width: 4.4rem;
      text-align: center;

      border: 1px solid rgba($color--black, 0.3);
      border-radius: 3rem;

      @media (hover: hover) {
        transition: all 0.2s;
        &:hover {
          color: $color--white;
          background-color: $color--6;
        }
      }
    }
  }

  &__menu{
    position: fixed;
    right: 2em;
    top: 5rem;
    // transform: translateY(-50%);
    width: 5rem;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    // align-items: center;
    justify-content: center;
    
    cursor: pointer;
    span{
      display: block;
      height: 1px;
      width: 80%;
      background-color: $color--white;
      transform-origin: right center;
      transition: all .3s;
      &:nth-of-type(2){
        width: 100%;
      }
      .is--menu &{
        &:nth-of-type(1){
          transform:  translate(2px, 8px) rotate(45deg) scale(.5) ;
        }
        &:nth-of-type(3){
          transform:  translate(2px, -8px) rotate(-45deg) scale(.5) ;
        }
      }
    }
    @include mediaQueries('ML', 'min') {
      display: none;
    }
  }
}
