// ==========================================================================
// = Variables
// ==========================================================================

@use "sass:math";

// ==================================================================
// = Breakpoints
// ==================================================================

// 480px / 720px / content(1180px) / 1280px / 1440px /
$breakpoints: (
  // XS: 320px,
  S: 360px,
  M: 768px,
  ML: 1024px,
  L: 1280px,
  XL: 1921px,
) !default;

// ==========================================================================
// = Fonts
// ==========================================================================

$font-size--default: 16px;
$font-size--base: math.div(10 * 100%, 16);

$font-weight--regular: 300;
$font-weight--medium: 400;
$font-weight--bold: 700;

$font-family--primary: 'Poppins', sans-serif;
$font-family--header: 'Noto Serif Display', serif;

// ==========================================================================
// = Colors
// ==========================================================================

// Fundamental colors
$color--black: #000;
$color--white: #fff;

$color--1: #4A3A3F;
$color--2: #844A7A;
$color--3: #D1788E;
$color--4: #C5D9DF;
$color--5: #768C94;
$color--6: #902E55;
$color--7: #D9F0F6;
$color--8: #C2C2C2;
$color--9: #2B2B2B;


// ...

$zIndex--header: 110;
$zIndex--hero: 100;
$zIndex--products: 90;
$zIndex--carousel: 80;
$zIndex--team: 70;
$zIndex--form: 60;
$zIndex--footer: 60;




// ...
